import { Controller } from '@hotwired/stimulus'

import Chart from 'chart.js/auto'

export default class extends Controller {
  static targets = ['chart']
  static values = {
    chartFinancingAmountSeries: Array,
    chartXaxisLabels: Array,
    currencySymbol: String,
    chartMerchantsOptedInSeries: Array,
  }

  connect() {
    this.charts()
  }

  charts() {
    new Chart(this.chartTarget, {
      type: 'line',
      options: {
        maintainAspectRatio: false,
        animations: false,
        scales: {
          y: {
            beginAtZero: true,
            suggestedMax: 100,
            ticks: {
              count: 11,
            },
          },
          yMerchants: {
            beginAtZero: true,
            suggestedMax: 20,
            position: 'right',
            ticks: {
              count: 11,
            },
          },
        },
      },
      data: {
        labels: this.chartXaxisLabelsValue,
        datasets: [
          {
            label: 'New Financings',
            data: this.chartFinancingAmountSeriesValue,
            tension: 0.2,
          },
          {
            label: 'Opted In Merchants',
            data: this.chartMerchantsOptedInSeriesValue,
            tension: 0.2,
            yAxisID: 'yMerchants',
          },
        ],
      },
    })
  }
}
