'use strict'

import '@i18n'

import '../../sass/partners/project.scss'

///////////////////
// StimulusJS
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

window.Stimulus = Application.start()
const context = require.context('./controllers', true, /\.js$/)
Stimulus.load(definitionsFromContext(context))
// /StimulusJS
///////////////////

// HTMX
import 'htmx.org'
window.htmx = require('htmx.org')
// /HTMX

//////////////////////////////////////
// Front Dashboard Theme JS

/* Project specific Javascript goes here. */
import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js'
import Dropzone from 'dropzone'

window.TomSelect = TomSelect
window.Dropzone = Dropzone

import './front_dashboard/theme-custom'
import './front_dashboard/hs.core'

import './front_dashboard/hs.theme-appearance'
import './front_dashboard/hs.tom-select'
import './front_dashboard/hs.chartjs'
import './front_dashboard/hs.dropzone'
// import './company_settings_form'

import HSAddField from './front_dashboard/hs-add-field'
window.HSAddField = HSAddField

import HSStepForm from './front_dashboard/hs-step-form'
window.HSStepForm = HSStepForm
// /Front Dashboard Theme JS
//////////////////////////////////////
