import { Controller } from '@hotwired/stimulus'
import HSSideNav from '../../front_dashboard/vendor/hs-navbar-vertical-aside/src/hs-navbar-vertical-aside.js'

export default class extends Controller {
  static targets = ['']

  connect() {
    this._loadMiniSidebarState()

    new HSSideNav('.js-navbar-vertical-aside').init()
  }

  _loadMiniSidebarState() {
    const body = document.getElementsByTagName('body')[0],
      isMini =
        window.localStorage.getItem('hs-navbar-vertical-aside-mini') === null
          ? false
          : window.localStorage.getItem('hs-navbar-vertical-aside-mini')

    if (isMini) {
      body.classList.add('navbar-vertical-aside-mini-mode')
    }
  }
}
