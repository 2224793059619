import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

var i18num
var numParser

///////////////////////////
// For number parsing
class NumberParser {
  constructor(locale) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6)
    const numerals = [
      ...new Intl.NumberFormat(locale, { useGrouping: false }).format(
        9876543210,
      ),
    ].reverse()
    const index = new Map(numerals.map((d, i) => [d, i]))
    this._group = new RegExp(
      `[${parts.find((d) => d.type === 'group').value}]`,
      'g',
    )
    this._decimal = new RegExp(
      `[${parts.find((d) => d.type === 'decimal').value}]`,
    )
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g')
    this._index = (d) => index.get(d)
  }
  parse(string) {
    return (string = string
      .trim()
      .replace(this._group, '')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index))
      ? +string
      : NaN
  }
}

///////////////////////////
// For i18n
i18n.use(LanguageDetector).init(
  {
    detection: {
      // Detect language from the `lang` attribute of the `html` tag
      htmlTag: document.documentElement,
      order: ['htmlTag', 'navigator'],
      caches: [],
    },
    fallbackLng: 'en-SG',
    // resources: {
    //   en: {
    //     translation: {
    //       // Add your translations here
    //     }
    //   },
    //   // Add other languages here
    // }
  },
  (err, t) => {
    i18num = Intl.NumberFormat(i18n.language)
    numParser = new NumberParser(i18n.language)
  },
)

export { i18n, i18num, numParser }
